import React, { PureComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Img from 'gatsby-image';
import Observer from 'react-intersection-observer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './_commercialization.scss';
import * as globalAnimation from '../../animations/globalAnimation';

class Commercialization extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.fadeUp(this.tl, '.animate-primary-market-research');
  }

  animateStrategicThought = InView => {
    const tlStrategic = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeLeft(tlStrategic, '.animate-strategic');
      globalAnimation.fadeRight(tlStrategic, '.animate-thought');
      globalAnimation.fadeUp(tlStrategic, '.animate-img-fade-up');
    }
  };

  animateBottomLine = InView => {
    const tlBottom = new TimelineLite({ force3D: !0, yoyo: true });

    if (InView) {
      globalAnimation.fadeUp(tlBottom, '.animate-bottom-line');
    }
  };

  render() {
    const {
      data: { tsideOne, tsideTwo, tsideThree, slider, image },
    } = this.props;
    return (
      <section>
        <Row className="justify-content-center mt-lg-5">
          <Col xs={11} lg={6}>
            <div className="d-lg-flex justify-content-center animate-primary-market-research">
              <div className="my-3 my-lg-0">
                <div style={{ minHeight: '120px', minWidth: '160px' }}>
                  <Img
                    fluid={tsideOne.image.childImageSharp.fluid}
                    className="mx-auto mx-lg-0 triangle_side"
                  />
                </div>
              </div>
              <div className="ml-lg-4">
                <h5 className="text-appcolor text-center text-lg-left">{tsideOne.title}</h5>
                <p>{tsideOne.description}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Observer onChange={this.animateStrategicThought} triggerOnce>
          <Row className="justify-content-center align-items-center mb-5">
            <Col xs={11} lg={2}>
              <div className="animate-strategic">
                <div className="text-center text-lg-left my-3">
                  <div style={{ minHeight: '120px', minWidth: '160px' }}>
                    <Img
                      fluid={tsideTwo.image.childImageSharp.fluid}
                      className="mx-auto mx-lg-0 triangle_side"
                    />
                  </div>
                </div>
                <div>
                  <h5 className="text-appcolor text-center text-lg-left">{tsideTwo.title}</h5>
                  <p>{tsideTwo.description}</p>
                </div>
              </div>
            </Col>
            <Col xs={6} className="d-none d-lg-block animate-img-fade-up">
              <img src={image.publicURL} className="" alt="" />
            </Col>

            <Col xs={11} lg={2}>
              <div className="animate-thought">
                <div className="text-center text-lg-left my-3">
                  <div style={{ minHeight: '120px', minWidth: '160px' }}>
                    <Img
                      fluid={tsideThree.image.childImageSharp.fluid}
                      className="mx-auto mx-lg-0 triangle_side"
                    />
                  </div>
                </div>
                <div>
                  <h5 className="text-appcolor text-center text-lg-left">{tsideThree.title}</h5>
                  <p>{tsideThree.description}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Observer>
        <Observer onChange={this.animateBottomLine} triggerOnce>
          <Row className="quotes-img justify-content-center text-center">
            <Col xs={10} className="quotes_sign animate-bottom-line">
              <div className="quotes">
                <h5 className="description">{slider.text}</h5>
                <hr />
                <h6 className="text-appcolor font-weight-bold">{slider.author}</h6>
              </div>
            </Col>
          </Row>
        </Observer>
      </section>
    );
  }
}

export default Commercialization;
