import React, { PureComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import Observer from 'react-intersection-observer';
import { TimelineLite } from 'gsap';
import YellowLine from '../../../static/assets/yellow_line.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_howdo.scss';
import * as globalAnimation from '../../animations/globalAnimation';

class HowDo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.fadeUp(this.tl, '.animate-fade-up');
  }

  animateSideBar = InView => {
    const tlSideBar = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlSideBar, '.animate-sidebar-down');
      globalAnimation.fadeUp(tlSideBar, '.animate-sidebar-up');
      globalAnimation.fadeUp(tlSideBar, '.animate-sidebar-right');
      globalAnimation.fadeUp(tlSideBar, '.animate-global-scale');
    }
  };

  animateClarity = InView => {
    const tlClarity = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlClarity, '.animate-clarity');
    }
  };

  render() {
    const {
      data: {
        sectionOne: { title1, title2, art, science },
        sectionTwo: { description, icons },
        sectionThree,
        sectionFour: { lists },
      },
    } = this.props;
    return (
      <section>
        <Row className="science-art justify-content-center mt-lg-5 animate-fade-up">
          <Col
            xs={12}
            className="d-md-flex text-center align-items-end justify-content-center mt-3"
          >
            <div className="d-lg-flex flex-lg-row">
              <h3 className="text-white">{title1}&nbsp;</h3>
              <h3 className="text-lightblue">{title2}</h3>
            </div>
          </Col>

          <Col xs={12} className="d-flex justify-content-center align-items-center">
            <div>
              <img src={science.image.publicURL} alt="" height="80px" width="80px" />
            </div>

            <div className="position-relative position-relative line_yellow px-lg-3">
              <YellowLine />
            </div>
            <div>
              <img src={art.image.publicURL} alt="" height="80px" width="80px" />
            </div>
          </Col>

          <Col xs={12} lg={2} className="text-center d-flex justify-content-center">
            {/* <h3 className="text-white">Our work is a</h3> */}
            <div>
              <h1 className="text-white font-weight-bold mb-lg-3">{science.title}</h1>
              {science.data.map(list => (
                <h5 className="text-white">{list.text}</h5>
              ))}
            </div>
          </Col>
          <Col xs={12} lg={1} className="text-center">
            <h1 className="font-weight-bold text-orange">&</h1>
          </Col>
          <Col
            xs={12}
            lg={2}
            className="text-center d-flex justify-content-center justify-content-md-center"
          >
            <div>
              <h1 className="text-lightblue font-weight-bold mb-lg-3">{art.title}</h1>
              {art.data.map(list => (
                <h5 className="text-lightblue">{list.text}</h5>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={11} className="world-map d-flex text-center justify-content-center">
            <h4
              className="text-appcolor font-weight-bold align-self-center w-75 animate-global-scale"
              style={{ lineHeight: '150%' }}
            >
              {description}
            </h4>
          </Col>
          <Col
            xs={12}
            lg={1}
            className="bg-appcolor-dark d-flex align-items-center justify-content-center flex-column"
          >
            <Observer onChange={this.animateSideBar} triggerOnce>
              <div className="my-auto text-center">
                {icons.map(icon => (
                  <div className={`my-5 ${icon.class}`}>
                    <div>
                      <img src={icon.image.publicURL} alt="" height="60px" />
                    </div>
                    <h6 className="text-lightblue font-weight-bold my-3">{icon.text}</h6>
                  </div>
                ))}
              </div>
            </Observer>
          </Col>
        </Row>
        <Observer onChange={this.animateClarity} triggerOnce>
          <Row className="clarity">
            <Col xs={12} lg={{ span: 3, offset: 8 }} className="align-self-center">
              <h3 className="text-white p-3 my-auto animate-clarity" style={{ lineHeight: '150%' }}>
                {sectionThree.description}
              </h3>
            </Col>
          </Row>
        </Observer>
        <Row className="bg-appcolor text-white">
          <Col xs={12} className="mx-auto">
            <div className="future-world-text circle-white-bullets animate-clarity">
              <ul id="lists" className="text-wight">
                {lists.map(list => (
                  <li className="my-3 pl-1">
                    <h6>{list.text}</h6>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default HowDo;
