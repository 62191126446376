import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import CommercializationImg from '../../static/img/blueprint_do/Commercialization Strategy.png';
import HowDoImg from '../../static/img/blueprint_do/annie-spratt-604131-unsplash.png';
import HowDo from '../components/BlueprintDo/HowDo';
import Commercialization from '../components/BlueprintDo/Commercialization';
import '../sass/_blueprint-do.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as globalAnimation from '../animations/globalAnimation';

export default class ClientTestimonials extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'Commercialization',
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.fadeUp(this.tl, '.animate-fade-up');
  }

  render() {
    const { activePage } = this.state;
    const commercializationonclick = () => {
      this.setState({
        activePage: 'Commercialization',
      });
    };

    const howdoclick = () => {
      this.setState({
        activePage: 'How We Do',
      });
    };

    const {
      data: {
        markdownRemark: {
          frontmatter: { CommercializationStrategy, HowWeDo },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="What does Blueprint Do?" />

        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper">
                <div className="wrapper">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">
                      What does BluePrint do?
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle">
                      What does BluePrint do?
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={10} lg={3}>
              <div className="brick position-relative mb-4">
                <img src={CommercializationImg} alt="" className="img-fluid" />
                <a onClick={commercializationonclick}>
                  <div className="front_text d-flex p-2 p-lg-3">
                    <h4 className="text-white align-self-end">
                      Commercialization <br /> Strategy
                    </h4>
                  </div>
                  <div
                    className={`${
                      activePage === 'Commercialization'
                        ? 'active-description_block'
                        : 'description_block'
                    } p-2 p-lg-3`}
                  >
                    <h4 className="text-white align-self-end">
                      Commercialization <br /> Strategy
                    </h4>
                  </div>
                </a>
              </div>
              {activePage === 'Commercialization' ? (
                <div className="blue_line">
                  <hr className="mb-4 mb-lg-0" />
                </div>
              ) : null}
            </Col>
            <Col xs={10} lg={3}>
              <div className="brick position-relative mb-4">
                <img src={HowDoImg} alt="" className="img-fluid" />
                <a onClick={howdoclick}>
                  <div className="front_text d-flex p-2 p-lg-3">
                    <h4 className="text-white align-self-end">
                      How we <br /> do it?
                    </h4>
                  </div>
                  <div
                    className={`${
                      activePage === 'How We Do' ? 'active-description_block' : 'description_block'
                    } p-2 p-lg-3`}
                  >
                    <h4 className="text-white align-self-end">
                      How we <br /> do it?
                    </h4>
                  </div>
                </a>
              </div>
              {activePage === 'How We Do' ? (
                <div className="blue_line">
                  <hr className="mb-4 mb-lg-0" />
                </div>
              ) : null}
            </Col>
          </Row>
          {activePage === 'How We Do' ? (
            <HowDo data={HowWeDo} />
          ) : (
            <Commercialization data={CommercializationStrategy} />
          )}
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "what-does-blueprint-do" } }) {
      frontmatter {
        title
        cards {
          title1
          title2
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          page
        }
        CommercializationStrategy {
          image {
            publicURL
          }
          tsideOne {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
          }
          tsideTwo {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
          }
          tsideThree {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
          }
          slider {
            text
            author
          }
        }
        HowWeDo {
          sectionOne {
            title1
            title2
            science {
              title
              image {
                publicURL
              }
              data {
                text
              }
            }
            art {
              title
              image {
                publicURL
              }
              data {
                text
              }
            }
          }
          sectionTwo {
            description
            icons {
              image {
                publicURL
              }
              text
              class
            }
          }
          sectionThree {
            description
          }
          sectionFour {
            lists {
              text
            }
          }
        }
      }
    }
  }
`;
